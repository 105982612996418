import './style.css';
import gibHubIcon from './gitHub-black.svg';

const BtnGitHub = ({ link }) => {
    return (
        <a className="btn-outline" href={link} target="_blank" rel="noreferrer">
            <img src={gibHubIcon} alt="" />
            GitHub repo
        </a>
    );
}

export default BtnGitHub;