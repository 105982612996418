import { useEffect } from 'react';
import { useLocalStorage } from '../../utils/useLocalStorage';
import detectDarkMode from '../../utils/detectDarkMode';
import './style.css';
import sun from './sun.svg';
import moon from './moon.svg';

const BtnDarkMode = () => {
    const [darkMode, setDarkMode] = useLocalStorage('darkMode', detectDarkMode());

    useEffect(() => {
        if (darkMode === 'dark') {
            document.querySelector('body').classList.add('dark');
            // btnRef.current.classList.add('dark-mode-btn--active');
        } else {
            document.querySelector('body').classList.remove('dark');
            // btnRef.current.classList.remove('dark-mode-btn--active');
        }
    }, [darkMode]);

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', event => {
            const newColorScheme = event.matches ? 'dark' : 'light';
            setDarkMode(newColorScheme);
        });
    }, [setDarkMode]);

    const toggleDarkMode = (event) => {
        setDarkMode(currentValue => currentValue === 'light' ? 'dark' : 'light');
    }

    return (
        <button className={`dark-mode-btn${darkMode === 'dark' ? ' dark-mode-btn--active' : ''}`} onClick={toggleDarkMode}>
            <img src={sun} alt="Light mode" className="dark-mode-btn__icon" />
            <img src={moon} alt="Dark mode" className="dark-mode-btn__icon" />
        </button>
    );
}

export default BtnDarkMode;