import project01 from '../img/projects/01.jpg';
import project01Big from '../img/projects/01-big.jpg';
import project02 from '../img/projects/02.jpg';
import project02Big from '../img/projects/02-big.jpg';
import project03 from '../img/projects/03.jpg';
import project03Big from '../img/projects/03-big.jpg';
import project04 from '../img/projects/04.jpg';
import project04Big from '../img/projects/04-big.jpg';
import project05 from '../img/projects/05.jpg';
import project05Big from '../img/projects/05-big.jpg';
import project06 from '../img/projects/06.jpg';
import project06Big from '../img/projects/06-big.jpg';


const projects = [
    {
        title: 'Gaming streaming portal',
        skills: 'React, Node js, Mongo DB',
        img: project01,
        imgBig: project01Big,
        gitHubLink: 'https://github.com'
    },
    {
        title: 'Video service',
        skills: 'React, PHP, MySql',
        img: project02,
        imgBig: project02Big,
        gitHubLink: 'https://github.com'
    },
    {
        title: 'Video portal',
        skills: 'VueJs, Node js, Mongo DB',
        img: project03,
        imgBig: project03Big,
        gitHubLink: 'https://github.com'
    },
    {
        title: 'Dating app',
        skills: 'React Native',
        img: project04,
        imgBig: project04Big
    },
    {
        title: 'Landing',
        skills: 'HTML, SCSS, JS',
        img: project05,
        imgBig: project05Big
    },
    {
        title: 'Gaming community',
        skills: 'React, PHP, MySql',
        img: project06,
        imgBig: project06Big
    },

];

export { projects };