import Header from "../components/header/Header";

const Home = () => {
    return (
        <>
            <Header />

            <main className="section">
                <div className="container">
                    <h2 className="title-2">Frontend</h2>
                    <p>JavaScript, TypeScript, ReactJS, HTML, CSS, SASS, NPM, BootStrap, Webpack</p>
                </div>
            </main>
        </>
    );
}

export default Home;